import {
  mdiEmailCheckOutline,
  mdiEmailPlusOutline,
  mdiEmailAlertOutline,
  mdiEmailSearchOutline,
  mdiEmailSyncOutline,
  mdiEmailRemove,
  mdiEmailOffOutline,
} from '@mdi/js'

export const responseStatus = Object.freeze({
  sent: {
    icon: mdiEmailCheckOutline,
    color: '#8ed380',
  },
  ready: {
    icon: mdiEmailPlusOutline,
    color: '#81D4FA',
  },
  manual: {
    icon: mdiEmailSearchOutline,
    color: '#ffb344',
  },
  empty: {
    icon: mdiEmailAlertOutline,
    color: '#B0BEC5',
  },
  discarded: {
    icon: mdiEmailRemove,
    color: '#EF9A9A',
  },
  to_reassess: {
    icon: mdiEmailSyncOutline,
    color: '#d2a0ff',
  },
  failed: {
    icon: mdiEmailOffOutline,
    color: '#EF9A9A',
  },
})
