<template>
  <UiDialog :dialog="dialog" scrollable :max-width="500" @close="$emit('close')">
    <template v-slot:header>
      <UiTitle :title="$t('autoreply.reviews.modal.reportResponse.title')" />
    </template>

    <template v-slot:body>
      <div class="tw-px-4 tw-pt-6 sm:tw-px-6">
        {{ $t('autoreply.reviews.modal.reportResponse.description') }}

        <div class="tw-grid tw-w-full tw-mt-6">
          <div class="required-asterisk">
            <UiLabel id="feeling" :label="$t('autoreply.reviews.modal.reportResponse.label.feeling')" />
            <v-select
              v-model="feeling"
              :error-messages="feelingErrors"
              id="feeling"
              solo
              flat
              outlined
              :items="feelings"
              :placeholder="$t('autoreply.reviews.modal.reportResponse.placeholder.feeling')"
            />
          </div>
          <div class="required-asterisk">
            <UiLabel id="words" :label="$t('autoreply.reviews.modal.reportResponse.label.words')" />
            <v-text-field
              v-model="words"
              :error-messages="wordsErrors"
              id="words"
              solo
              flat
              outlined
              :placeholder="$t('autoreply.reviews.modal.reportResponse.placeholder.words')"
            />
          </div>
          <div class="required-asterisk">
            <UiLabel id="feedback" :label="$t('autoreply.reviews.modal.reportResponse.label.feedback')" />
            <v-textarea
              v-model="feedback"
              :error-messages="feedbackErrors"
              id="feedback"
              solo
              flat
              outlined
              auto-grow
              no-resize
              :placeholder="$t('autoreply.reviews.modal.reportResponse.placeholder.feedback')"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn type="submit" rounded text @click="$emit('close')">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-spacer />
      <v-btn type="submit" rounded color="primary" :loading="loading" :disabled="loading" @click="reportResponse">
        {{ $t('button.send') }}
      </v-btn>
    </template>
  </UiDialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import UiDialog from '@/components/UI/Dialog.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiLabel from '@/components/UI/Label.vue'

let initialData = () => ({
  feeling: '',
  feedback: '',
  words: '',
})

export default {
  name: 'AutoReplyReviewsReportResponse',
  components: {
    UiDialog,
    UiTitle,
    UiLabel,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    review: {
      type: Object,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [validationMixin],
  data: () => initialData(),
  computed: {
    feelings() {
      return [
        {
          text: this.$t('autoreply.reviews.modal.fullReview.feelings.PO'),
          value: 'PO',
        },
        {
          text: this.$t('autoreply.reviews.modal.fullReview.feelings.NG'),
          value: 'NG',
        },
        {
          text: this.$t('autoreply.reviews.modal.fullReview.feelings.NE'),
          value: 'NE',
        },
        {
          text: this.$t('autoreply.reviews.modal.fullReview.feelings.BL'),
          value: 'BL',
        },
      ]
    },
    feelingErrors() {
      const errors = []
      if (!this.$v.feeling.$dirty) return errors
      !this.$v.feeling.required && errors.push(this.$t('error.required'))
      return errors
    },
    wordsErrors() {
      const errors = []
      if (!this.$v.words.$dirty) return errors
      !this.$v.words.required && errors.push(this.$t('error.required'))
      return errors
    },
    feedbackErrors() {
      const errors = []
      if (!this.$v.feedback.$dirty) return errors
      !this.$v.feedback.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    reportResponse() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('report', {
          feedback: this.feedback,
          bot_token: this.review.bot_token,
          words: this.words,
          comment: this.review.comment,
          expected_connotation: this.feeling,
        })
      }
    },
    resetData() {
      Object.assign(this.$data, initialData())
      this.$v.$reset()
    },
  },
  validations() {
    return {
      feeling: {
        required,
      },
      words: {
        required,
      },
      feedback: {
        required,
      },
    }
  },
}
</script>
