export const locales = Object.freeze([
  {
    id: 48,
    code: 'en',
    codeRegion: 'en-en',
    text: 'English',
  },
  {
    id: 76,
    code: 'fr',
    codeRegion: 'fr-fr',
    text: 'Français',
  },
])
