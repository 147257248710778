<template>
  <UiDialog :dialog="dialog" scrollable @close="$emit('close')" :max-width="500">
    <template v-slot:header>
      <UiTitle :title="$t('autoreply.reviews.modal.sendAllConfirm.title')" />
    </template>
    <template v-slot:body>
      <div
        class="tw-px-4 tw-py-6 sm:tw-px-6"
        v-dompurify-html="$tc('autoreply.reviews.modal.sendAllConfirm.body', total, { count: total })"
    /></template>
    <template v-slot:actions>
      <v-btn type="submit" rounded text @click="$emit('close')">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-spacer />
      <v-btn type="submit" rounded color="primary" :loading="loading" :disabled="loading" @click="confirmSendAll">
        {{ $t('button.confirm') }}
      </v-btn>
    </template>
  </UiDialog>
</template>

<script>
import UiDialog from '@/components/UI/Dialog.vue'
import UiTitle from '@/components/UI/Title.vue'

export default {
  name: 'AutoReplyReviewsSendAllConfirm',
  components: {
    UiDialog,
    UiTitle,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    total: {
      type: Number,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    confirmSendAll() {
      this.$emit('confirm')
    },
  },
}
</script>

<style lang="scss" scoped>
.auto-reply-engine-send-all-confirm {
  &__card {
    @apply tw-rounded-2xl;

    &__header,
    &__actions {
      @apply tw-p-4 sm:tw-px-6;
    }

    &__body {
      @apply tw-h-full tw-overflow-y-auto tw-p-4 sm:tw-p-6;
    }

    &__actions {
      @apply tw-flex tw-justify-between;
    }
  }
}
</style>
