<template>
  <div class="auto-reply-reviews-alert" :class="{ 'theme--dark': $vuetify.theme.dark }">
    <v-alert
      dense
      text
      dismissible
      :close-icon="icons.mdiClose"
      :icon="icons.mdiAlertOutline"
      type="warning"
      class="tw-m-4 sm:tw-mx-6 tw-mb-0"
    >
      <span v-dompurify-html="$t('autoreply.reviews.alert.text', { date: formatedDate(date, 'L') })"></span
      >&nbsp;<router-link :to="{ name: 'AutoReplySettings' }">{{ $t('autoreply.reviews.alert.link') }}</router-link
      >.
    </v-alert>
  </div>
</template>

<script>
import { mdiAlertOutline, mdiClose } from '@mdi/js'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'AutoReplyReviewsAlert',
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiAlertOutline,
      mdiClose,
    },
    formatedDate,
  }),
}
</script>

<style lang="scss" scoped>
.auto-reply-reviews-alert {
  background: map-get($material-light, 'banner', 'background');

  &.theme--dark {
    background: map-get($material-dark, 'banner', 'background');
  }
}
</style>
