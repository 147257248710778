<template>
  <UiDialog :dialog="dialog" scrollable :max-width="700" @close="$emit('close')" no-header>
    <template v-slot:body>
      <div class="tw-px-4 tw-py-6 sm:tw-px-6" v-if="review">
        <AutoReplyReviewsUserReview
          :review="review"
          :deactivating="deactivating"
          :current-user="currentUser"
          @deactivate="deactivateReview"
        />
        <div class="tw-mt-6">
          <UiTitle small>
            {{ $t(`autoreply.reviews.modal.fullReview.title.response`) }}
            <v-tooltip top v-if="!review.responses && review.response_status !== 'sent'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="tw-ml-1" v-bind="attrs" v-on="on">{{ icons.mdiInformation }}</v-icon>
              </template>
              <span>
                {{ $t('autoreply.reviews.modal.fullReview.tooltip') }}
              </span>
            </v-tooltip>
          </UiTitle>
          <UiSubtitle
            :subtitle="
              $t('autoreply.reviews.modal.fullReview.subtitle.date', {
                date: formatedDate(review.response_date, 'L LT'),
              })
            "
            v-if="
              review.response_date && (review.response_status === 'sent' || review.response_status === 'to_reassess')
            "
          />
          <UiSubtitle
            :subtitle="
              $t('autoreply.reviews.modal.fullReview.subtitle.who', {
                who: review.who,
              })
            "
            v-if="review.who"
          />
          <div class="tw-flex tw-items-center">
            <!-- TABS -->
            <v-tabs
              v-model="currentTab"
              v-if="review.responses"
              :height="40"
              class="tw-mt-1"
              @change="setResponse(true)"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(value, key, idx) in review.details"
                :key="`detail${idx}`"
                :disabled="!value.length && currentTab !== key"
                :value="key"
                :href="`#${key}`"
                @click="setResponse(true)"
              >
                <v-badge :color="feelingsColor(key)" :content="value.length" inline :value="value.length">
                  <span v-if="$vuetify.breakpoint.xsOnly">
                    <v-icon>{{ icons[key] }}</v-icon>
                  </span>
                  <span v-else-if="$vuetify.breakpoint.smAndUp">{{
                    $t(`autoreply.reviews.modal.fullReview.feelings.${key}`)
                  }}</span>
                </v-badge>
              </v-tab>
            </v-tabs>

            <v-spacer />

            <!-- CHANGE LANUGAGE -->
            <v-menu offset-y left max-height="350">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom transition="fade-transition">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                      :disabled="localizating"
                      :loading="localizating"
                      class="tw-mr-1"
                    >
                      <v-icon>{{ icons.mdiWeb }}</v-icon>
                    </v-btn>
                  </template>
                  {{ $t(`autoreply.reviews.modal.fullReview.cta.changeLanguage`) }}
                </v-tooltip>
              </template>

              <v-list>
                <v-list-item v-for="(locale, index) in locales" :key="index" link @click="changeLocale(locale.code)">
                  <v-list-item-icon class="tw-mr-4">
                    <v-img :src="require(`@/assets/images/flags/${locale.code}.svg`)" max-width="24" />
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ locale.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- REROLL RESPONSES -->
            <v-tooltip bottom transition="fade-transition">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-if="$vuetify.breakpoint.smAndUp"
                  class="tw-mr-1"
                  icon
                  v-on="{ ...tooltip }"
                  :disabled="rerolling"
                  :loading="rerolling"
                  @click="$emit('reroll')"
                >
                  <v-icon>{{ icons.mdiAutorenew }}</v-icon>
                </v-btn>
              </template>
              {{ $t(`autoreply.reviews.modal.fullReview.cta.reroll`) }}
            </v-tooltip>

            <!-- SAVE RESPONSE -->
            <v-tooltip bottom transition="fade-transition">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-if="$vuetify.breakpoint.smAndUp"
                  class="tw-mr-1"
                  icon
                  v-on="{ ...tooltip }"
                  :disabled="saving"
                  :loading="saving"
                  @click="saveDraft"
                >
                  <v-icon>{{ icons.mdiContentSaveOutline }}</v-icon>
                </v-btn>
              </template>
              {{ $t(`autoreply.reviews.modal.fullReview.cta.saveDraft`) }}
            </v-tooltip>

            <!-- REPORT RESPONSE -->
            <v-tooltip bottom transition="fade-transition">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-if="review.responses && $vuetify.breakpoint.smAndUp"
                  class="tw-mr-1"
                  icon
                  v-on="{ ...tooltip }"
                  @click="reportResponse"
                >
                  <v-icon>{{ icons.mdiAlertOutline }}</v-icon>
                </v-btn>
              </template>
              {{ $t(`autoreply.reviews.modal.fullReview.cta.reportResponse`) }}
            </v-tooltip>

            <!-- MENU MOBILE -->
            <v-menu offset-y left max-height="350">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn
                  v-if="$vuetify.breakpoint.xsOnly"
                  icon
                  v-bind="attrs"
                  v-on="{ ...menu }"
                  :disabled="saving || rerolling"
                  :loading="saving || rerolling"
                >
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item link @click="$emit('reroll')">
                  <v-list-item-icon class="tw-mr-4">
                    <v-icon>{{ icons.mdiAutorenew }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('autoreply.reviews.modal.fullReview.cta.reroll') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="saveDraft">
                  <v-list-item-icon class="tw-mr-4">
                    <v-icon>{{ icons.mdiContentSaveOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('autoreply.reviews.modal.fullReview.cta.saveDraft') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="reportResponse" v-if="review.responses">
                  <v-list-item-icon class="tw-mr-4">
                    <v-icon>{{ icons.mdiAlertOutline }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('autoreply.reviews.modal.fullReview.cta.reportResponse') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <!-- TABS ITEMS -->
          <v-tabs-items v-model="currentTab">
            <v-tab-item
              v-for="(value, key, idx) in review.details"
              :key="`detail-content${idx}`"
              :disabled="!value.length"
              :value="key"
              transition="fade"
            >
              <v-textarea
                v-model="response"
                solo
                flat
                outlined
                :error-messages="responseErrors"
                :placeholder="$t('autoreply.reviews.modal.fullReview.placeholder.reply')"
                :counter="$v.response.$params.maxLength.max"
                no-resize
                auto-grow
                class="tw-mt-2"
                :key="currentTab"
              />
            </v-tab-item>
          </v-tabs-items>

          <v-menu v-model="confirm" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <a
                class="tw-font-medium"
                v-bind="attrs"
                v-on="on"
                v-if="
                  review.response_status &&
                  review.response_status !== 'discarded' &&
                  review.response_status !== 'sent' &&
                  review.response_status !== 'to_reassess'
                "
              >
                {{ $t('autoreply.reviews.modal.fullReview.label.discard') }}
              </a>
            </template>
            <v-card>
              <div class="tw-px-4 tw-pt-4">
                {{ $t('label.confirm') }}
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded text @click="confirm = false"> {{ $t('label.no') }} </v-btn>
                <v-btn rounded color="primary" text @click="updateStatus('discarded')">
                  {{ $t('label.yes') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <a
            class="tw-font-medium"
            v-if="review.response_status && review.response_status === 'to_reassess'"
            @click="noUpdate"
          >
            {{ $t('autoreply.reviews.modal.fullReview.label.noUpdate') }}
          </a>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn rounded text color="primary" @click="$emit('close')">
        {{ $t('button.close') }}
      </v-btn>
      <v-spacer />
      <v-btn type="submit" rounded color="primary" :disabled="sending" :loading="sending" @click="submitResponse">
        <span v-if="review && review.response_status === 'sent'">{{ $t('button.update') }}</span>
        <span v-else>{{ $t('button.send') }}</span>
      </v-btn>
    </template>
  </UiDialog>
</template>

<script>
import { formatedDate } from '@/utils/date.util'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import {
  mdiAutorenew,
  mdiInformation,
  mdiDotsVertical,
  mdiAlertOutline,
  mdiContentSaveOutline,
  mdiWeb,
  mdiThumbUpOutline,
  mdiThumbDownOutline,
  mdiThumbsUpDownOutline,
  mdiCancel,
} from '@mdi/js'
import { locales } from '@/config/locales.config'
import UiDialog from '@/components/UI/Dialog.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'
import AutoReplyReviewsUserReview from '@/components/AutoReply/Reviews/UserReview.vue'

const REPLY_MAX_LENGTH = 3600

let initialData = () => ({
  response: null,
  currentTab: null,
  confirm: false,
})

export default {
  name: 'AutoReplyReviewsFullReview',
  components: {
    UiDialog,
    UiTitle,
    UiSubtitle,
    AutoReplyReviewsUserReview,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    review: {
      type: Object,
    },
    rerolling: {
      type: Boolean,
      required: false,
      default: false,
    },
    localizating: {
      type: Boolean,
      required: false,
      default: false,
    },
    sending: {
      type: Boolean,
      required: false,
      default: false,
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    deactivating: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  mixins: [validationMixin],
  data: () => ({
    ...initialData(),
    formatedDate,
    icons: {
      mdiAutorenew,
      mdiInformation,
      mdiDotsVertical,
      mdiAlertOutline,
      mdiContentSaveOutline,
      mdiWeb,
      PO: mdiThumbUpOutline,
      NG: mdiThumbDownOutline,
      NE: mdiThumbsUpDownOutline,
      BL: mdiCancel,
    },
    locales,
  }),
  watch: {
    review: {
      handler() {
        this.response = null
        this.currentTab = this.setCurrentTab()
        this.setResponse()
      },
      deep: true,
    },
  },
  computed: {
    responseErrors() {
      const errors = []
      if (!this.$v.response.$dirty) return errors
      !this.$v.response.required && errors.push(this.$t('error.required'))
      !this.$v.response.maxLength &&
        errors.push(
          this.$t('error.maxLength', {
            minLength: this.$v.response.$params.maxLength.max,
          })
        )
      return errors
    },
  },
  methods: {
    setCurrentTab() {
      if (!this.review) {
        return null
      }

      if (!this.review.responses) {
        return 'PO'
      } else {
        return Object.keys(this.review.responses)[0]
      }
    },
    confirmSendAll() {
      this.$emit('confirm')
    },
    feelingsColor(key) {
      switch (key) {
        case 'PO':
          return 'success'
        case 'NG':
          return 'error'
        case 'NE':
          return 'warning'
        case 'BL':
          return 'black lighten-1'
      }
    },
    setResponse(force = false) {
      if (this.review?.responses && (!this.review?.response || force)) {
        this.response = this.review.responses[this.currentTab]
      } else if (this.review?.default_response && !this.review?.response) {
        this.response = this.review.default_response
      } else if (this.review?.response) {
        this.response = this.review.response
      }
    },
    submitResponse() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit('reply', {
          body: this.response,
        })
      }
    },
    saveDraft() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$emit('save', {
          body: this.response,
        })
      }
    },
    changeLocale(locale) {
      this.$emit('updateLocale', {
        locale,
      })
    },
    resetData() {
      Object.assign(this.$data, initialData())
      this.$v.$reset()
    },
    updateStatus(status) {
      this.$emit('updateStatus', {
        autoreply_status: status,
      })
    },
    noUpdate() {
      this.$emit('updateStatus', {
        to_reassess: '0',
      })
    },
    deactivateReview() {
      this.$emit('deactivateReview', this.review.id)
    },
    reportResponse() {
      this.$emit('report')
    },
  },
  validations() {
    return {
      response: {
        required,
        maxLength: maxLength(REPLY_MAX_LENGTH),
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.auto-reply-engine-send-all-confirm {
  &__card {
    @apply tw-rounded-2xl;

    &__header,
    &__actions {
      @apply tw-p-4 sm:tw-px-6;
    }

    &__body {
      @apply tw-h-full tw-overflow-y-auto tw-p-4 sm:tw-p-6;
    }

    &__actions {
      @apply tw-flex tw-justify-between;
    }
  }
}
</style>
