<template>
  <div class="auto-reply-reviews-user-review">
    <div class="auto-reply-reviews-user-review__header">
      <v-avatar :size="28" class="auto-reply-reviews-user-review__header__source" v-if="review.origin">
        <v-img :src="require(`@/assets/images/logo-${review.origin.toLowerCase()}.svg`)" />
      </v-avatar>
      <div>
        <div class="auto-reply-reviews-user-review__header__username">
          {{ review.username }}
        </div>
        <div class="auto-reply-reviews-user-review__header__info">
          <v-rating
            color="#FFD600"
            length="5"
            readonly
            size="20"
            background-color="#e0e0e0"
            :empty-icon="icons.mdiStar"
            :value="review.grade"
            dense
            class="auto-reply-reviews-user-review__header__info__rating"
          ></v-rating>
          <div class="auto-reply-reviews-user-review__header__info__date">
            {{ formatedDate(review.created_at, 'll LT') }}
          </div>
        </div>
      </div>
      <v-spacer />

      <!-- SHOW SEMANTIC -->
      <v-tooltip bottom transition="fade-transition">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn v-if="review.formatted_comment" icon class="mr-1" v-on="{ ...tooltip }" @click="toggleSemantic">
            <v-icon v-if="!showSemantic">{{ icons.mdiEyeOutline }}</v-icon>
            <v-icon v-else>{{ icons.mdiEyeOffOutline }}</v-icon>
          </v-btn>
        </template>
        <span v-if="!showSemantic">{{ $t('autoreply.reviews.userReview.cta.showSemantic') }}</span>
        <span v-else>{{ $t('autoreply.reviews.userReview.cta.hideSemantic') }}</span>
      </v-tooltip>

      <!-- OPEN NEW TAB -->
      <v-tooltip bottom transition="fade-transition">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-if="review.location_url && $vuetify.breakpoint.smAndUp"
            :href="review.location_url"
            target="_blank"
            icon
            class="mr-1"
            v-on="{ ...tooltip }"
          >
            <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
          </v-btn>
        </template>
        <span v-if="review.origin.toLowerCase() === 'gmb'">{{ $t('autoreply.reviews.userReview.cta.openGmb') }}</span>
        <span v-else-if="review.origin.toLowerCase() === 'gatoreviews'">{{
          $t('autoreply.reviews.userReview.cta.openGtr')
        }}</span>
      </v-tooltip>

      <!-- REPORT REVIEW -->
      <v-tooltip bottom transition="fade-transition">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            icon
            v-on="{ ...tooltip }"
            :href="`mailto:hello@gatoreviews.com?subject=${$t('autoreply.reviews.userReview.cta.reportReview')} [ID: ${
              review.id
            }] `"
            target="_blank"
          >
            <v-icon>{{ icons.mdiFlagOutline }}</v-icon>
          </v-btn>
        </template>
        {{ $t('autoreply.reviews.userReview.cta.reportReview') }}
      </v-tooltip>

      <!-- OFFSENTIVE REVIEW -->
      <v-menu offset-y left max-height="350">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom transition="fade-transition">
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                v-if="$vuetify.breakpoint.smAndUp && review.origin.toLowerCase() === 'gmb' && review.placeid"
                icon
                v-on="{ ...tooltip }"
                :href="`https://search.google.com/local/reviews?placeid=${review.placeid}`"
                target="_blank"
              >
                <v-icon>{{ icons.mdiCommentAlertOutline }}</v-icon>
              </v-btn>
              <v-btn
                v-else-if="
                  $vuetify.breakpoint.smAndUp &&
                  review.origin.toLowerCase() === 'gatoreviews' &&
                  isNavigationAccess([USER_ROLES.superAdmin], currentUser)
                "
                icon
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                :disabled="deactivating"
                :loading="deactivating"
              >
                <v-icon>{{ icons.mdiCommentAlertOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('autoreply.reviews.userReview.cta.offensiveReview') }}
          </v-tooltip>
        </template>

        <v-card>
          <div class="tw-px-4 tw-pt-4">
            {{ $t('autoreply.reviews.userReview.cta.deactivateReview') }}
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded text @click="confirm = false"> {{ $t('label.no') }} </v-btn>
            <v-btn rounded color="primary" text @click="$emit('deactivate')">
              {{ $t('label.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!-- MENU MOBILE -->
      <v-menu offset-y left max-height="350">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn v-if="$vuetify.breakpoint.xsOnly" icon v-bind="attrs" v-on="{ ...menu }">
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="review.location_url" link :href="review.location_url" target="_blank">
            <v-list-item-icon class="tw-mr-4">
              <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <span v-if="review.origin.toLowerCase() === 'gmb'">{{
                  $t('autoreply.reviews.userReview.cta.openGmb')
                }}</span>
                <span v-else-if="review.origin.toLowerCase() === 'gatoreviews'">{{
                  $t('autoreply.reviews.userReview.cta.openGtr')
                }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="$emit('offensive')">
            <v-list-item-icon class="tw-mr-4">
              <v-icon>{{ icons.mdiCommentAlertOutline }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t('autoreply.reviews.userReview.cta.offensiveReview') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            :href="`mailto:hello@gatoreviews.com?subject=${$t('autoreply.reviews.userReview.cta.reportReview')} [ID: ${
              review.id
            }] `"
          >
            <v-list-item-icon class="tw-mr-4">
              <v-icon>{{ icons.mdiFlagOutline }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t('autoreply.reviews.userReview.cta.reportReview') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="auto-reply-reviews-user-review__store">{{ review.shop_name }}</div>
    <div
      class="auto-reply-reviews-user-review__comment"
      :class="{ 'auto-reply-reviews-user-review__comment--short': short }"
      v-if="review.comment"
    >
      <span v-if="showSemantic && review.formatted_comment" v-dompurify-html="formattedDetailedComment" />
      <span v-else v-dompurify-html="formattedComment" />
    </div>
  </div>
</template>

<script>
import {
  mdiStar,
  mdiFlagOutline,
  mdiOpenInNew,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiDotsVertical,
  mdiCommentAlertOutline,
} from '@mdi/js'
import { formatedDate } from '@/utils/date.util'
import { isNavigationAccess } from '@/utils/auth.util'
import { USER_ROLES } from '@/config/permissions.config'

export default {
  name: 'AutoReplyReviewsUserReview',
  props: {
    review: {
      type: Object,
      required: true,
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
    deactivating: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiStar,
      mdiFlagOutline,
      mdiOpenInNew,
      mdiEyeOutline,
      mdiEyeOffOutline,
      mdiDotsVertical,
      mdiCommentAlertOutline,
    },
    formatedDate,
    showSemantic: true,
    isNavigationAccess,
    USER_ROLES,
  }),
  computed: {
    formattedComment() {
      return this.review.comment.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    formattedDetailedComment() {
      return this.review.formatted_comment.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
  },
  methods: {
    sourceColor(review) {
      switch (review.origin) {
        case 'GMB':
          return 'primary'
        case 'GATOREVIEWS':
          return '#059347'
      }
    },
    toggleSemantic() {
      this.showSemantic = !this.showSemantic
    },
  },
}
</script>

<style lang="scss">
.auto-reply-reviews-user-review {
  &__header {
    @apply tw-flex tw-items-center;

    &__source {
      @apply tw-mr-3;
    }

    &__username {
      @apply tw-font-medium tw-truncate tw-leading-none;
    }

    &__info {
      @apply tw-flex tw-items-center tw--ml-1;

      &__rating {
        @apply tw-inline-flex;
      }

      &__date {
        @apply tw-ml-2 tw-text-sm tw-text-gray-500 dark:tw-text-gray-400 tw-truncate;
      }
    }
  }

  &__store {
    @apply tw-text-sm tw-mt-1;
  }

  &__comment {
    @apply tw-text-gray-500 tw-mt-2 dark:tw-text-gray-400;

    &--short {
      @include text-ellipsis($lines: 3, $font-size: 0.875rem, $margin-top: 0, $line-height: 1.5);
    }

    .positive,
    .negative,
    .blacklist,
    .neutral {
      @apply tw-font-bold;
    }

    .positive {
      color: var(--v-success-base);
    }

    .negative {
      color: var(--v-error-base);
    }

    .neutral {
      color: var(--v-warning-base);
    }

    .blacklist {
      @apply tw-text-black;
    }
  }
}
</style>
