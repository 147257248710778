<template>
  <v-navigation-drawer
    :value="drawer"
    absolute
    temporary
    right
    :width="$vuetify.breakpoint.xsOnly ? '100%' : 320"
    @input="toggleFilters"
  >
    <template v-slot:prepend>
      <v-toolbar class="primary tw-flex-grow-0" height="56" :elevation="2">
        <v-btn icon dark @click="toggleFilters(false)" v-if="$vuetify.breakpoint.xsOnly">
          <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
        </v-btn>
        <v-toolbar-title class="tw-text-base tw-font-medium tw-text-white">
          {{ $t('autoreply.reviews.filters.title') }}
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <!-- READY REVIEWS -->
    <div class="tw-p-4">
      <v-switch
        v-model="responsesReadyOnly"
        :label="$t('autoreply.reviews.filters.label.responsesReady')"
        hide-details
        class="tw-mt-0 tw-pt-0"
        @change="toggleReady"
      />
    </div>
    <v-divider />
    <div class="tw-p-4 tw-grid tw-gap-6">
      <!-- START DATE -->
      <div>
        <UiLabel id="dateFrom" :label="$t('autoreply.reviews.filters.label.dateFrom')" />
        <v-menu
          id="dateFrom"
          ref="dateFrom"
          v-model="dateFromMenu"
          :close-on-content-click="false"
          :return-value.sync="dateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="computedDateFrom"
              :placeholder="$t('autoreply.reviews.filters.placeholder.dateFrom')"
              solo
              flat
              outlined
              readonly
              clearable
              hide-details
              v-bind="attrs"
              v-on="on"
              :disabled="responsesReadyOnly"
              @click:clear="clearDateFrom"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            :locale="$i18n.locale"
            :first-day-of-week="1"
            @input="$refs.dateFrom.save(dateFrom)"
          ></v-date-picker>
        </v-menu>
      </div>
      <!-- END DATE -->
      <div>
        <UiLabel id="dateTo" :label="$t('autoreply.reviews.filters.label.dateTo')" />
        <v-menu
          id="dateTo"
          ref="dateTo"
          v-model="dateToMenu"
          :close-on-content-click="false"
          :return-value.sync="dateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="computedDateTo"
              :placeholder="$t('autoreply.reviews.filters.placeholder.dateTo')"
              solo
              flat
              outlined
              readonly
              clearable
              hide-details
              v-bind="attrs"
              v-on="on"
              :disabled="responsesReadyOnly"
              @click:clear="clearDateTo"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            :locale="$i18n.locale"
            :first-day-of-week="1"
            @input="$refs.dateTo.save(dateTo)"
          ></v-date-picker>
        </v-menu>
      </div>
      <!-- RATING -->
      <div>
        <UiLabel id="grades" :label="$t('autoreply.reviews.filters.label.grade')" />
        <v-select
          v-model="grades"
          id="grades"
          solo
          flat
          outlined
          multiple
          chips
          deletable-chips
          clearable
          :items="computedGrades"
          :placeholder="$t('autoreply.reviews.filters.placeholder.grade')"
          hide-details
          :disabled="responsesReadyOnly"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primary"
              close
              @click:close="removeGrade(data.item.value)"
            >
              {{ data.item.value
              }}<v-icon right small color="#FFD600">
                {{ icons.mdiStar }}
              </v-icon>
            </v-chip>
          </template>
        </v-select>
      </div>
      <!-- LOCATION -->
      <div class="tw-overflow-x-hidden">
        <UiLabel id="locations" :label="$t('autoreply.reviews.filters.label.location')" />
        <v-autocomplete
          v-model="locations"
          id="locations"
          solo
          flat
          outlined
          multiple
          chips
          deletable-chips
          clearable
          :items="availableFilters.shops"
          :item-text="item => (item.internal_id ? `${item.shop_name} [${item.internal_id}]` : item.shop_name)"
          item-value="id"
          :label="$t('autoreply.reviews.filters.placeholder.location')"
          :placeholder="$t('autoreply.reviews.filters.placeholder.location')"
          hide-details
          :disabled="responsesReadyOnly"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primary"
              close
              @click:close="removeLocation(data.item)"
            >
              <span class="text-truncate"> {{ data.item.shop_name }}</span>
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <!-- LOCATIONS GROUP -->
      <div class="tw-overflow-x-hidden">
        <UiLabel id="groups" :label="$t('autoreply.reviews.filters.label.groups')" />
        <v-autocomplete
          v-model="groups"
          id="groups"
          solo
          flat
          outlined
          multiple
          chips
          deletable-chips
          clearable
          :items="availableFilters.shop_groups"
          :label="$t('autoreply.reviews.filters.placeholder.groups')"
          :placeholder="$t('autoreply.reviews.filters.placeholder.groups')"
          hide-details
          :disabled="responsesReadyOnly"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primary"
              close
              @click:close="removeGroup(data.item)"
            >
              <span class="text-truncate"> {{ data.item }}</span>
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <!-- COMMENT -->
      <div>
        <UiLabel id="withComment" :label="$t('autoreply.reviews.filters.label.withComment')" />
        <div class="tw-flex tw-gap-4">
          <v-checkbox
            :label="$t('autoreply.reviews.filters.withComment.with')"
            v-model="withComment"
            :value="true"
            hide-details
          />
          <v-checkbox
            :label="$t('autoreply.reviews.filters.withComment.without')"
            v-model="withComment"
            :value="false"
            hide-details
          />
        </div>
      </div>
      <!-- REPONSE STATUS -->
      <div>
        <UiLabel id="status" :label="$t('autoreply.reviews.filters.label.status')" />
        <v-select
          v-model="status"
          id="status"
          solo
          flat
          outlined
          clearable
          :items="availableFilters.response_statuses"
          :placeholder="$t('autoreply.reviews.filters.placeholder.status')"
          hide-details
          :disabled="responsesReadyOnly"
        >
          <template v-slot:selection="data">
            {{ $t(`autoreply.reviews.filters.answer.${data.item}`) }}
          </template>
          <template v-slot:item="data"> {{ $t(`autoreply.reviews.filters.answer.${data.item}`) }} </template>
        </v-select>
      </div>
      <!-- REVIEW SOURCE -->
      <div class="tw-overflow-x-hidden">
        <UiLabel id="origins" :label="$t('autoreply.reviews.filters.label.origin')" />
        <v-select
          v-model="origins"
          id="origins"
          solo
          flat
          outlined
          multiple
          chips
          deletable-chips
          clearable
          :items="computedOrigins"
          :placeholder="$t('autoreply.reviews.filters.placeholder.origin')"
          hide-details
          :disabled="responsesReadyOnly"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="primary"
              close
              @click:close="removeOrigin(data.item.value)"
            >
              <v-avatar left>
                <v-img :src="require(`@/assets/images/logo-${data.item.value.toLowerCase()}.svg`)" />
              </v-avatar>
              <span class="text-truncate">
                {{ $t(`autoreply.reviews.filters.source.${data.item.value}`) }}
              </span>
            </v-chip>
          </template>
          <!-- <template v-slot:item="data"> {{ $t(`autoreply.reviews.filters.source.${data.item}`) }} </template> -->
        </v-select>
      </div>
    </div>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block color="primary" text rounded @click="onSubmit">
          {{ $t('button.validate') }}
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mdiArrowLeft, mdiStar, mdiCloseCircle } from '@mdi/js'
import { formatedDate } from '@/utils/date.util'
import UiLabel from '@/components/UI/Label.vue'

export default {
  name: 'AutoReplyReviewsFilters',
  components: {
    UiLabel,
  },
  props: {
    drawer: {
      type: Boolean,
      required: false,
      default: false,
    },
    availableFilters: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiArrowLeft,
      mdiStar,
      mdiCloseCircle,
    },
    dateFromMenu: false,
    dateFrom: null,
    dateToMenu: false,
    dateTo: null,
    origins: [],
    locations: [],
    groups: [],
    grades: [],
    status: null,
    withComment: [],
    responsesReadyOnly: false,
    formatedDate,
  }),
  computed: {
    computedDateFrom() {
      return this.dateFrom ? formatedDate(this.dateFrom, 'L') : ''
    },
    computedDateTo() {
      return this.dateTo ? formatedDate(this.dateTo, 'L') : ''
    },
    computedGrades() {
      return this.availableFilters.grades.map(grade => ({
        text: this.$tc(`autoreply.reviews.filters.grade`, grade, { grade }),
        value: grade,
      }))
    },
    computedOrigins() {
      return this.availableFilters.origins.map(origin => ({
        text: this.$t(`autoreply.reviews.filters.source.${origin}`),
        value: origin,
      }))
    },
    withCommentAllSelect() {
      return this.withComment.length === 2
    },
  },
  methods: {
    toggleFilters(bool) {
      this.$emit('toggle', bool)
    },
    onSubmit() {
      this.$emit('filter', {
        from: this.dateFrom,
        to: this.dateTo,
        origins: this.origins,
        shops: this.locations,
        grades: this.grades,
        response_status: this.status,
        shop_group: this.groups,
        with_comment: this.withCommentAllSelect ? [] : this.withComment,
      })
    },
    removeGrade(item) {
      this.grades = this.grades.filter(g => g !== item)
    },
    removeLocation(item) {
      this.locations = this.locations.filter(l => l !== item.id)
    },
    removeGroup(item) {
      this.groups = this.groups.filter(g => g !== item)
    },
    removeOrigin(item) {
      this.origins = this.origins.filter(o => o !== item)
    },
    clearDateFrom() {
      this.dateFrom = null
    },
    clearDateTo() {
      this.dateTo = null
    },
    clearWithComment() {
      this.withComment = []
    },
    toggleReady(bool) {
      this.clearDateFrom()
      this.clearDateTo()
      this.clearWithComment()
      this.origins = []
      this.locations = []
      this.grades = []
      this.status = null
      this.$emit('filter', {
        response_status: bool ? 'ready' : null,
      })
    },
  },
}
</script>
