<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="autoreply-review" v-else>
      <AutoReplyReviewsAlert
        v-if="
          reviewsPagination &&
          reviewsPagination.promotions.expired &&
          isNavigationAccess([USER_ROLES.superAdmin, USER_ROLES.agency, USER_ROLES.brandAdmin], currentUser)
        "
        :date="reviewsPagination.promotions.endDate"
      />
      <UiBanner
        :title="
          $t('autoreply.reviews.title', {
            count: reviewsPagination ? reviewsPagination.total : 0,
          })
        "
      >
        <template v-slot:actions>
          <v-btn
            color="primary"
            rounded
            :fab="$vuetify.breakpoint.xsOnly"
            :small="$vuetify.breakpoint.xsOnly"
            :elevation="2"
            :loading="sending"
            :disabled="(reviewsPagination && reviewsPagination.total_ready === 0) || sending"
            class="tw-ml-4"
            @click="dialogConfirm = true"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">{{ icons.mdiAutoFix }}</v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">{{
              $tc('autoreply.reviews.cta.sendAll', reviewsPagination ? reviewsPagination.total_ready : 0, {
                count: reviewsPagination ? reviewsPagination.total_ready : 0,
              })
            }}</span>
          </v-btn>
          <v-badge overlap color="primary" :content="filtersLength" :value="filtersLength">
            <v-btn
              outlined
              class="tw-ml-4"
              color="primary"
              rounded
              :icon="$vuetify.breakpoint.xsOnly"
              @click="filtersDrawer = true"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">{{ icons.mdiFilterOutline }}</v-icon>
              <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('button.filter') }}</span>
            </v-btn>
          </v-badge>
          <v-tooltip bottom color="error" :disabled="!exportIsNotValid" transition="fade-transition">
            <template v-slot:activator="{ on: tooltip }">
              <div class="tw-inline-block" v-on="{ ...tooltip }">
                <v-btn
                  color="primary"
                  rounded
                  text
                  :loading="exporting"
                  :disabled="exporting || exportIsNotValid"
                  :icon="$vuetify.breakpoint.xsOnly"
                  class="tw--mr-1 tw-ml-4"
                  @click="exportData"
                >
                  <v-icon :left="$vuetify.breakpoint.smAndUp">{{ icons.mdiDownload }}</v-icon>
                  <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('button.export') }}</span>
                </v-btn>
              </div>
            </template>
            {{ $t('autoreply.reviews.tooltip.export') }}
          </v-tooltip>
        </template>
      </UiBanner>
      <UiContainer no-padding>
        <div class="autoreply-review__table-container">
          <v-data-table
            :headers="headers"
            :items="reviews"
            fixed-header
            height="100%"
            class="autoreply-review__table-container__table"
            :options.sync="options"
            :server-items-length="reviewsPagination ? reviewsPagination.total : 0"
            :footer-props="{
              itemsPerPageOptions: [25, 50, 100],
            }"
            :loading="refreshing"
            multi-sort
            @click:row="getFullReview"
          >
            <template v-slot:[`item.origin`]="{ item }">
              <div class="tw-flex tw-justify-center">
                <v-img :src="require(`@/assets/images/logo-${item.origin.toLowerCase()}.svg`)" max-width="24" />
              </div>
            </template>
            <template v-slot:[`item.grade`]="{ item }">
              <v-rating
                color="#FFD600"
                length="5"
                readonly
                size="20"
                background-color="#e0e0e0"
                :empty-icon="icons.mdiStar"
                :value="item.grade"
                dense
              ></v-rating>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ formatedDate(item.created_at, 'L') }}
            </template>
            <template v-slot:[`item.comment`]="{ item }">
              <div class="autoreply-review__table-container__table__comment">{{ item.comment }}</div>
            </template>
            <template v-slot:[`item.response_status`]="{ item }">
              <v-chip class="ma-2" :color="responseStatus[item.response_status].color" text-color="white" small>
                <v-icon left small>
                  {{ responseStatus[item.response_status].icon }}
                </v-icon>
                {{ $t(`autoreply.reviews.status.${item.response_status}`) }}
              </v-chip>
            </template>
            <template v-slot:[`item.locale`]="{ item }">
              <div class="tw-flex tw-justify-center">
                <v-img :src="getFlagSrc(item)" max-width="24" v-if="item.locale && getFlagSrc(item)" />
                <span v-if="item.locale && !getFlagSrc(item)">{{ item.locale.toUpperCase() }}</span>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu offset-y left max-height="350">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip left transition="fade-transition">
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        :loading="deactivatingId === item.review_id"
                        :disabled="deactivatingId !== null"
                      >
                        <v-icon>{{ icons.mdiPower }}</v-icon>
                      </v-btn>
                    </template>
                    <span> {{ $t('autoreply.reviews.cta.deactivate') }} </span>
                  </v-tooltip>
                </template>

                <v-card>
                  <div class="tw-px-4 tw-pt-4">
                    {{ $t('label.confirm') }}
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn rounded text @click="confirm = false"> {{ $t('label.no') }} </v-btn>
                    <v-btn rounded color="primary" text @click="onDeactivate(item.review_id)">
                      {{ $t('label.yes') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </UiContainer>
      <AutoReplyReviewsFilters
        :drawer="filtersDrawer"
        :available-filters="availableFilters"
        @toggle="toggleFilters"
        @filter="setFilters"
      />
      <AutoReplyReviewsFullReview
        :dialog="currentReview !== null"
        :review="currentReview"
        :rerolling="rerolling"
        :deactivating="deactivating"
        :localizating="localizating"
        :sending="sending"
        :saving="saving"
        :current-user="currentUser"
        ref="fullReview"
        @reply="onReply"
        @reroll="onReroll"
        @updateLocale="onUpdateLocale"
        @updateStatus="onUpdateStatus"
        @deactivateReview="onDeactivate"
        @report="dialogReport = true"
        @save="onSave"
        @close="onClose"
      />
      <AutoReplyReviewsSendAllConfirm
        :dialog="dialogConfirm"
        :total="reviewsPagination ? reviewsPagination.total_ready : 0"
        :loading="sending"
        @close="dialogConfirm = false"
        @confirm="replyToAll"
      />
      <AutoReplyReviewsReportResponse
        ref="reportResponse"
        :dialog="dialogReport"
        :loading="reporting"
        :review="currentReview"
        @close="dialogReport = false"
        @report="onReport"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiMagnify, mdiFilterOutline, mdiStar, mdiDownload, mdiAutoFix, mdiPower } from '@mdi/js'
import { formatedDate } from '@/utils/date.util'
import { responseStatus } from '@/config/responseStatus.config'
import { isNavigationAccess } from '@/utils/auth.util'
import { USER_ROLES } from '@/config/permissions.config'
import UiContainer from '@/components/UI/Container.vue'
import UiBanner from '@/components/UI/Banner.vue'
import AutoReplyReviewsFilters from '@/components/AutoReply/Reviews/Filters.vue'
import AutoReplyReviewsFullReview from '@/components/AutoReply/Reviews/FullReview.vue'
import AutoReplyReviewsSendAllConfirm from '@/components/AutoReply/Reviews/SendAllConfirm.vue'
import AutoReplyReviewsReportResponse from '@/components/AutoReply/Reviews/ReportResponse.vue'
import AutoReplyReviewsAlert from '@/components/AutoReply/Reviews/Alert.vue'

export default {
  name: 'AutoReplyReviews',
  components: {
    UiContainer,
    UiBanner,
    AutoReplyReviewsFilters,
    AutoReplyReviewsFullReview,
    AutoReplyReviewsSendAllConfirm,
    AutoReplyReviewsReportResponse,
    AutoReplyReviewsAlert,
  },
  async created() {
    this.setUpdating(true)
    this.resetState()
    await this.getFilters()
    this.setUpdating(false)
  },
  data: () => ({
    refreshing: false,
    options: {},
    icons: {
      mdiMagnify,
      mdiFilterOutline,
      mdiStar,
      mdiDownload,
      mdiAutoFix,
      mdiPower,
    },
    formatedDate,
    filtersDrawer: false,
    filters: null,
    exporting: false,
    rerolling: false,
    localizating: false,
    sending: false,
    saving: false,
    reporting: false,
    deactivating: false,
    deactivatingId: null,
    dialogConfirm: false,
    dialogReport: false,
    responseStatus,
    isNavigationAccess,
    USER_ROLES,
  }),
  watch: {
    options: {
      handler(newOptions, oldOptions) {
        if (newOptions !== oldOptions) this.loadReviews()
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      reviews: state => state.autoreply.reviews,
      reviewsPagination: state => state.autoreply.reviewsPagination,
      availableFilters: state => state.autoreply.availableFilters,
      currentReview: state => state.autoreply.currentReview,
      currentUser: state => state.backoffice.currentUser,
    }),
    exportIsNotValid() {
      return this.reviewsPagination?.total > 4000
    },
    headers() {
      const headers = [
        {
          text: this.$t('autoreply.reviews.table.date'),
          value: 'created_at',
          class: 'th-date',
        },
        {
          text: this.$t('autoreply.reviews.table.origin'),
          value: 'origin',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('autoreply.reviews.table.location'),
          value: 'shop_name',
          class: 'th-location',
        },
        {
          text: this.$t('autoreply.reviews.table.username'),
          value: 'username',
        },
        {
          text: this.$t('autoreply.reviews.table.grade'),
          value: 'grade',
        },
        {
          text: this.$t('autoreply.reviews.table.comment'),
          value: 'comment',
          class: 'th-comment',
        },
        {
          text: this.$t('autoreply.reviews.table.status'),
          value: 'response_status',
          class: 'th-comment',
        },
        {
          text: this.$t('autoreply.reviews.table.locale'),
          value: 'locale',
          width: 130,
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('autoreply.reviews.table.internalId'),
          value: 'shop_internal_id',
          sortable: false,
        },
      ]
      if (isNavigationAccess([USER_ROLES.superAdmin], this.currentUser)) {
        headers.push({
          text: 'Actions',
          sortable: false,
          value: 'actions',
        })
      }
      return headers
    },
    filtersLength() {
      if (this.filters) {
        const emptyFilters = Object.values(this.filters).filter(f => f === null || (Array.isArray(f) && f.length === 0))
        return Object.values(this.filters).length - emptyFilters.length
      }
      return 0
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getReviews: 'autoreply/getReviews',
      getReview: 'autoreply/getReview',
      resetReview: 'autoreply/resetReview',
      getFilters: 'autoreply/getFilters',
      rerollResponse: 'autoreply/rerollResponse',
      sendResponse: 'autoreply/sendResponse',
      saveResponse: 'autoreply/saveResponse',
      updateReview: 'autoreply/updateReview',
      resetState: 'autoreply/resetState',
      getExport: 'autoreply/getExport',
      sendAllReply: 'autoreply/sendAllReply',
      reportResponse: 'autoreply/reportResponse',
      deactivateReview: 'autoreply/deactivateReview',
      setAlert: 'backoffice/setAlert',
    }),
    async loadReviews() {
      this.refreshing = true
      await this.getReviews({ tableParams: this.options, filters: this.filters })
      this.refreshing = false
    },
    toggleFilters(bool) {
      this.filtersDrawer = bool
    },
    setFilters(filters) {
      this.filters = filters
      this.options = {
        ...this.options,
        page: 1,
      }
      this.filtersDrawer = false
    },
    async exportData() {
      this.exporting = true
      try {
        await this.getExport(this.filters)
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.exporting = false
    },
    getFlagSrc(item) {
      try {
        return require(`@/assets/images/flags/${item.locale}.svg`)
      } catch {
        return null
      }
    },
    async getFullReview(item) {
      this.refreshing = true
      try {
        await this.getReview(item.review_id)
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.refreshing = false
    },
    async onReroll() {
      this.rerolling = true
      try {
        await this.rerollResponse()
        this.setAlert({
          color: 'info',
          text: this.$t('autoreply.reviews.notification.responseUpdated'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.rerolling = false
    },
    async onUpdateLocale(payload) {
      this.localizating = true
      try {
        await this.updateReview(payload)
        await this.rerollResponse()
        this.setAlert({
          color: 'info',
          text: this.$t('autoreply.reviews.notification.localeUpdated'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.localizating = false
    },
    async onUpdateStatus(payload) {
      this.refreshing = true
      try {
        await this.updateReview(payload)
        this.onClose()
        await this.loadReviews()
        this.setAlert({
          color: 'info',
          text: this.$t('autoreply.reviews.notification.statusChanged'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.refreshing = false
    },
    async onReply(payload) {
      this.sending = true
      try {
        await this.sendResponse(payload)
        await this.loadReviews()
        this.setAlert({
          color: 'success',
          text: this.$t('autoreply.reviews.notification.replySent'),
        })
        this.onClose()
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.sending = false
    },
    async onSave(payload) {
      this.saving = true
      try {
        await this.saveResponse(payload)
        await this.loadReviews()
        this.setAlert({
          color: 'success',
          text: this.$t('autoreply.reviews.notification.replySaved'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.saving = false
    },
    async replyToAll() {
      this.sending = true
      this.refreshing = true
      try {
        const count = await this.sendAllReply(this.filters)
        await this.loadReviews()
        this.setAlert({
          color: 'success',
          text: this.$tc('autoreply.reviews.notification.allRepliesSent', count, { count }),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.refreshing = false
      this.sending = false
      this.dialogConfirm = false
    },
    onClose() {
      this.$refs.fullReview.resetData()
      this.resetReview()
    },
    async onReport(payload) {
      this.reporting = true
      try {
        await this.reportResponse(payload)
        this.setAlert({
          color: 'success',
          text: this.$t('autoreply.reviews.notification.responseReported'),
        })
        this.$refs.reportResponse.resetData()
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.reporting = false
      this.dialogReport = false
    },
    async onDeactivate(id) {
      this.deactivatingId = id
      this.deactivating = true
      try {
        await this.deactivateReview(id)
        this.onClose()
        await this.loadReviews()
        this.setAlert({
          color: 'success',
          text: this.$t('autoreply.reviews.notification.reviewDeactivated'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('error.notification.default'),
        })
      }
      this.deactivating = false
      this.deactivatingId = null
    },
  },
}
</script>

<style lang="scss">
.autoreply-review {
  @apply tw-w-full tw-h-full tw-flex tw-flex-col;

  &__table-container {
    @apply tw-absolute tw-right-0 tw-bottom-0 tw-left-0 tw-top-0;

    &__table {
      @apply tw-h-full tw-flex tw-flex-col;

      .th-date {
        min-width: 170px;
      }

      .th-location {
        min-width: 280px;
      }

      .th-comment {
        min-width: 300px;
      }

      tr:hover {
        cursor: pointer;
      }

      &__comment,
      &__response {
        @include text-ellipsis($lines: 3, $font-size: 0.875rem, $margin-top: 0, $line-height: 1.5);
      }
    }
  }
}
</style>
